<template>
  <div >


    <div id="wrap">
    <div id="naverIdLogin" style="display: none"></div>
			<div class="login-page">
				<div class="login-wrap">
					<div class="left"><img :src="`${publishpath}pub/images/bg-login.png`" alt=""></div>
					<div class="right">
						<div class="txt-div">
							<div class="vivid-txt"><img :src="`${publishpath}pub/login-vividmare-txt.svg`" alt=""></div>
							<p class="title">모바일 청첩장도<br />비비드마레</p>
							<div class="login-btn-w">
								<a href="javascript:" class="btn" @click="requestLogin()"><span>네이버 로그인</span></a>
								<a href="javascript:" class="btn" @click="popupSample()"><span>청첩장 샘플 구경하기 👀</span></a>
							</div>
						</div>
					</div>
					<div class="store-view-btn">
						<a href="javascript:" @click="popupPublic()">
							<img :src="`${publishpath}pub/images/icon-vividmare-store.svg`" alt="">
							<p class="txt"><span>비비드마레</span>공식스토어 바로가기</p>
						</a>
					</div>
				</div>
			</div>
    </div>

  </div>
</template>

<script>

export default {
  name: 'Login',
  created() {
  },
  mounted() {
  },
  methods: {
    requestLogin(){
	
	  location.href=`https://nid.naver.com/oauth2.0/authorize?client_id=${process.env.VUE_APP_NAVER_CLINET_ID}&response_type=code&redirect_uri=${process.env.VUE_APP_NAVER_REDIRECT_URL}`
      
      //var btnNaverLogin = document.getElementById("naverIdLogin").firstChild;
      //btnNaverLogin.click();
    },
	popupSample(){
		window.open(`${process.env.VUE_APP_BASE_API}/invitationurl?invitationAesId=jYeJ3mUaBJU/79NjzZ0COg==`)
	},
	popupPublic(){
		window.open('https://smartstore.naver.com/vividmare')
	}
  },
};
</script>


